<template>
  <div>
    <b-card>
      <b-row v-if="!this.scaleConnected">
        <b-col lg="12" sm="12" class="mt-2">
          <h2 class="mb-2" style="text-align:center; color: #EA5455">
            {{ errorText }}
          </h2>
        </b-col>
      </b-row>
      <!-- ITEMS -->
        <br>
        <br>

      <div>
        <!-- New Item Data -->
        <b-row class="justify-content-md-center">
          <b-col lg="12" sm="12" class="mt-2">
            <h1 class="mb-2" style="text-align:center; font-size: 7.3em" v-if="this.scaleConnected">
              {{ scaleData.quantity }} kgs
            </h1>
          </b-col>
        </b-row>
      </div>
        <br>
        <br>
        <br>
      <b-row class="mb-1">
        <b-col lg="12" sm="12">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Neto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in invoiceData.items" :key="index">
                    <td>{{ item.alias }}</td>
                    <td>
                        {{ formatNumber(item.quantityAdjustment) }} {{ spinButtonNames.includes(item.alias) ? "pza/s." : "kg" }}
                        {{ formatNumber(item.adjustment) > 0 ? "(-" + formatNumber(item.adjustment) + "kgs)": ""}}
                    </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="invoiceData.items.length == 0"
              class="mt-2"
              style="text-align: center"
            >
              <h6 class="mb-2">
                No hay registros.
              </h6>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- Save Order -->
    </b-card>

  </div>
</template>


<style lang="scss" scoped>
@import 'scaleCustomer.css';
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import vSelect from "vue-select";
import utils from "../../utils/utils.js";

import {
  BCard,
  BFormSelect,
  BFormInput,
  BRow,
  BCol,
  BLink,
  BFormTextarea,
  BButton,
  BModal,
  BInputGroupAppend,
  BSpinner,
  BOverlay,
  BFormSpinbutton,
  BFormCheckbox,
  BAvatar,
  VBTooltip,
  BTable,
  BInputGroup,
  BFormGroup,
  BTab,
  BTabs,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import notification from "./notification.mp3";

import io from "socket.io-client";
import EchartLine from "@/views/charts-and-maps/charts/echart/EchartLine.vue";

const socket = io.connect("http://127.0.0.1:5557", {
  // const socket = io.connect("ws://192.168.1.100:5000", {
  // cors: {
  //   origin: "https://calm-tree-0f70d8510.1.azurestaticapps.net",
  //   methods: ["GET", "POST"],
  //   credentials: true
  // },
  transports: ["websocket", "polling", "flashsocket"],
  allowEIO3: true,
  upgrade: false,
  secure: true,
  rejectUnauthorized: false,
});

export default {
  components: {
      EchartLine,
    vSelect,
    BCard,
    BFormSelect,
    BFormInput,
    BRow,
    BCol,
    BLink,
    BFormTextarea,
    BButton,
    BModal,
    BSpinner,
    BOverlay,
    BFormSpinbutton,
    BFormCheckbox,
    BAvatar,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BTab,
    BTabs,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      // Data
      orderTypes: [
        { id: 1, name: "Compra" },
        { id: 2, name: "Venta" },
      ],
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
      items: [{ value: null, text: "Selecciona un item" }],
      providerItems: [],
      providers: [],
      newWeight: '',
      paymentTypes: [],
      idPublicUser: null,
      publicUser: {},
      saveWithPointsButton: false,
      shipmentTypes: [],
      metals: [],
      metalItems: [],
      frequentItems: [],
      showBackButton: false,
      showMetals: true,
      showItems: false,
      showFrequent: false,
      publicUserOverlay: false,
      userFoundMsg: "",
      userFoundByName: undefined,
      userFoundByNameCall: false,
      firstName: null,
      lastName: null,
      errorRegisterMsg: false,
      registerPublicUserButton: false,
      // Inventory table & Modal
      inventoryColumns: [
        { key: "check", label: "✓", sortable: false },
        { key: "name", label: "Material", sortable: false },
      ],
      sortBy: "value",
      sortDesc: true,
      sortDirection: "desc",
      inventory: [],
      truckDeparture: [],

      // Cut and Inventory
      initialDate: this.$moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format("DD-MM-YYYY HH:mm"),
      finalDate: this.$moment()
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .format("DD-MM-YYYY HH:mm"),
      cutTabColumns: [
        { key: "alias", label: "Material", sortable: false },
        { key: "realQuantity", label: "Kilos", sortable: true },
      ],
      sortCut: "realPrice",
      cutTabOverlay: true,
      cutTabData: [],
      inventoryTabColumns: [
        { key: "name", label: "Material", sortable: true },
        { key: "quantity", label: "Kilos", sortable: true },
      ],
      sortInventory: "quantity",
      inventoryTabOverlay: true,
      inventoryTabData: [],

      // Last purchase orders
      orderColumns: [
        { key: "creationDate", label: "Fecha de creación", sortable: true },
        { key: "total", label: "Total", sortable: true },
        { key: "details", label: "Detalles", sortable: false },
      ],
      lastOrders: [],
      historyOverlay: true,

      spinButtonNames: ["Cubeta"],
      scaleData: {
        idItem: null,
        name: null,
        idOperation: 1,
        quantity: null,
        units: null,
        price: null,
      },

      invoiceData: {
        isProvider: true,
        idOrder: null,
        idPublicUser: null,
        points: 0,
        creationDate: this.$moment(new Date()).valueOf(),
        estimatedDate: this.$moment(new Date()).valueOf(),
        deliveryDate: this.$moment(new Date()).valueOf(),
        creationDateString: new Date(),
        estimatedDateString: this.$moment().format("YYYY-MM-DD"),
        deliveryDateString: this.$moment().format("YYYY-MM-DD"),
        idCompany: null,
        total: 0,
        discount: 0,
        tax: null,
        idCountryTax: 1,
        subTotal: null,
        idQuote: null,
        idPaymentStatus: 10,
        idShippingStatus: 7,
        idUser: null,
        paymentType: 3,
        creditDays: 0,
        deadlinePayment: null,
        deadlinePaymentString: null,
        subTotalLogisticPrice: 0,
        logisticPrice: 0,
        orderBalance: 0,
        balancesCommissions: [],
        note: null,
        items: [],
      },

      // Props
      saveButtonStatus: false,
      metalsOverlay: true,
      modalOverlay: false,
      scaleConnected: false,
      errorText: "Bascula no conectada",
      adjustment: 0,
      timerCount: 0,
      companyData: null,
      enablePrices: false,
      consecutiveTouches: 0,
      timeout: null,
      percentage: 0,
      password: "",
    };
  },
  mounted() {
    socket.on("connect", () => {
      this.scaleConnected = false;
      this.errorText = "Conectando con bascula...";
      console.log(socket.id);
      // setTimeout(function() {
      //   this.scaleConnected = true;
      //
      //   console.log("entra");
      //   socket.emit("readScale", "test");
      // }, 4000);
    });

    socket.on("disconnect", () => {
      this.scaleConnected = false;
      this.errorText = "Bascula no conectada";
    });

    socket.on('weight', (data) => {
      this.scaleConnected = true;
      this.scaleData.quantity = parseFloat(data);
    });
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  created() {
      console.log(this.invoiceData)
    this.logs = setInterval(this.getItemsFromLocalStorage, 500);
  },
  beforeRouteLeave(to, from, next) {
    // Evitar que se salgan de la pantalla y perder datos
    const answer = window.confirm(
      "¿Seguro que desea salir? Los cambios no guardados se perderán."
    );
    if (answer) {
      if (this.timer) clearInterval(this.timer);
      if (this.logs) clearInterval(this.logs);
      next();
    } else {
      next(false);
    }
  },
  methods: {
    beforeWindowUnload(e) {
      e.preventDefault();
      e.returnValue = "";
    },
    getItemsFromLocalStorage(){
        this.invoiceData.items = JSON.parse(localStorage.getItem("items")) ?? [];
    },
    unlockPrices(e) {
      // Touch
      this.consecutiveTouches += 1;
      if (!this.timeout) {
        this.timeout = setTimeout(() => {
          this.consecutiveTouches = 0;
          this.timeout = null;
        }, 750);
      }

      this.password = "";
      if (e.detail == 5 || this.consecutiveTouches == 5) {
        if (this.enablePrices) this.enablePrices = !this.enablePrices;
        else this.$bvModal.show("unlockPricesModal");
      }
    },
    changePercentage() {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (this.percentage > 100) this.percentage = 100;
      userData.percentageValue = parseInt(this.percentage);
      localStorage.setItem("userData", JSON.stringify(userData));
      this.percentage = 0;
    },
    modifyPrices() {
      if (this.password == "!Helios2022") {
        this.enablePrices = !this.enablePrices;
      }
    },
    getMetals(save) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.$axios
        .get(this.$services + "general/get_scale_metals", {
          params: {
            idCompany: userData.idCompany,
            role: "PROVEEDOR",
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.metals = res.data.data;
            this.frequentItems = this.metals[this.metals.length - 1].items;
            this.showFrequent = true;
            if (save) this.createOrderInPOS();
            // if (save) this.$bvModal.show("searchPublicUser");
          }
        })
        .catch((error) => {
          this.showToast("danger", String("Verifica la conexión"));
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.metalsOverlay = false;
        });
    },
    getPublicUser() {
      this.saveWithPointsButton = false;
      this.invoiceData.idPublicUser = null;
      this.publicUser = {};
      this.publicUserOverlay = true;
      this.userFoundMsg = "";

      this.$axios
        .get(this.$services + "users/get_publicUser", {
          params: {
            idPublicUser: this.idPublicUser,
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            if (res.data.data[0]) {
              this.publicUser = res.data.data[0];
              this.invoiceData.idPublicUser = this.publicUser.id;
              this.saveWithPointsButton = true;
              this.userFoundMsg = this.publicUser.name;
            } else {
              this.userFoundMsg = "Usuario no existe";
            }
            this.publicUserOverlay = false;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        });
    },
    getPublicUserByName() {
      this.userFoundByName = null;
      this.publicUserOverlay = true;
      this.$axios
        .get(this.$services + "users/get_publicUserByName", {
          params: {
            firstName: this.firstName,
            lastName: this.lastName,
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            if (res.data.data[0]) {
              this.userFoundByName = res.data.data[0];
            } else {
              this.userFoundByNameCall = true;
            }
            this.publicUserOverlay = false;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        });
    },
    createPublicUser() {
      this.publicUserOverlay = true;
      this.errorRegisterMsg = false;

      if (!this.firstName || !this.lastName) {
        this.errorRegisterMsg = true;
        this.publicUserOverlay = false;
        return;
      }
      this.errorRegisterMsg = false;
      this.$axios
        .post(this.$services + "users/create_publicUser", {
          firstName: this.firstName,
          lastName: this.lastName,
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            if (res.data.data[0]) {
              this.userFoundByName = res.data.data[0];
            }
            this.registerPublicUserButton = true;
            this.publicUserOverlay = false;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        });
    },
    clearUserInfo() {
      this.userFoundByName = null;
      this.firstName = null;
      this.lastName = null;
      this.userFoundByNameCall = false;
      this.errorRegisterMsg = false;
      this.registerPublicUserButton = false;
    },
    getClient() {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.$axios
        .get(this.$services + "clients/get_client", {
          params: {
            idCompany: userData.idCompany,
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.companyData = res.data.data;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        });
    },
    setMetal(items) {
      this.setbackButton();

      this.metalItems = items;
      this.showMetals = false;
      this.showItems = true;
      this.showBackButton = true;
      this.showFrequent = false;
    },
    resetButtonsStatus(ignoreIndex = -1) {
      // Deseleccionar los botones
      this.metals.forEach(function(metal, indexMetal) {
        metal.items.forEach(function(item, indexItem) {
          if (indexItem != ignoreIndex) metal.items[indexItem].status = false;
        });
      });
    },
    setbackButton() {
      this.metalItems = [];

      this.scaleData.idItem = null;
      this.scaleData.price = null;

      this.showMetals = true;
      this.showItems = false;
      this.showBackButton = false;
      this.showFrequent = true;

      this.resetButtonsStatus();
      this.scrollItems();
    },
    setItemData(item, index) {
      this.resetButtonsStatus(index);
      if (!this.spinButtonNames.includes(this.scaleData.name))
        this.scaleData.units = null;
      this.scaleData.idItem = item.id;
      this.scaleData.price = parseFloat(item.price).toFixed(2);
      this.scaleData.name = item.name;
      this.scaleData.idQuote = item.idQuote;
      this.scrollItems();
    },
    getInventory2() {
      this.modalOverlay = true;
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.$axios
        .get(this.$services + "inventory/get_inventory", {
          params: {
            idCompany: userData.idCompany,
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.inventory = res.data.data;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.modalOverlay = false;
        });
    },
    addNewItem() {
      // Faltan datos
      if (this.checkMissingParameters()) {
        this.validatorText();
        return;
      }

      socket.emit("snapshot", "test");

      let newItem = {
        idItem: this.scaleData.idItem,
        alias: this.scaleData.name,
        quantity: this.roundNumber(parseFloat(
          this.scaleData.units > 0
            ? this.scaleData.units
            : this.scaleData.quantity
        )),
        price: this.roundNumber(parseFloat(this.scaleData.price)),
        idMeasurementUnit: 1,
        jumbo: false,
        bucket: false,
        quantityJumbo: this.roundNumber(parseFloat(
          this.scaleData.units > 0
            ? this.scaleData.units
            : this.scaleData.quantity
        )),
        quantityBucket: this.roundNumber(parseFloat(
          this.scaleData.units > 0
            ? this.scaleData.units
            : this.scaleData.quantity
        )),
        subTotal: this.roundNumber(parseFloat(
          (this.scaleData.price *
            (this.scaleData.units > 0
              ? this.scaleData.units
              : this.scaleData.quantity))
        )),
        subTotalCommission: 0,
        idAction: 4,
        waste: 0,
        commissions: [],
        providers: [
          {
            idProvider: null,
            estimatedQuantity: null,
            deliveryQuantity: null,
            estimatedDate: this.$moment(new Date()).valueOf(),
            deliveryDate: this.$moment(new Date()).valueOf(),
            price: null,
            discount: 0,
            subTotal: null,
          },
        ],
      };

      // Meter en invoiceData
      this.invoiceData.items.unshift(newItem);

      // Guardar en localStorage
      const adjustment = 0.7;
      newItem.alteredQuantity = this.roundNumber(newItem.quantity * adjustment);
      newItem.alteredQuantityJumbo = this.roundNumber(newItem.quantityJumbo * adjustment);
      newItem.alteredQuantityBucket = this.roundNumber(newItem.quantityBucket * adjustment);
      newItem.alteredSubTotal = this.roundNumber(newItem.subTotal * adjustment);
      let items = JSON.parse(localStorage.getItem("items"));

      if (!items) items = [];

      items.push(newItem);
      localStorage.setItem("items", JSON.stringify(items));

      // Empezar contador para notificar orden abierta
      // if (!this.timer) {
      //   this.timerCount = 0;
      //   this.timer = setInterval(this.notifyUser, 60000); // Cada 1 minuto
      // } else {
      //   clearInterval(this.timer);
      //   this.timer = setInterval(this.notifyUser, 60000); // Cada 1 minuto
      // }

      // Reiniciar datos
      this.showFrequent = true;
      this.scaleData.quantity = null;
      this.scaleData.units = null;
      this.scaleData.name = null;
      this.setbackButton();
    },
    // prepareLogs() {
    //   this.$bvModal.show('logsModal');
    // },
    getInventory() {
      const userData = JSON.parse(localStorage.getItem("userData"));

      this.cutTabOverlay = true;
      this.$axios
        .get(this.$services + "orders/get_daily_cut", {
          params: {
            idCompany: userData.idCompany,
            initialDate: parseInt(
              this.$moment(this.initialDate, "DD-MM-YYYY HH:mm").format("x")
            ),
            finalDate: parseInt(
              this.$moment(this.finalDate, "DD-MM-YYYY HH:mm").format("x")
            ),
            tz: this.moment_tz.tz.guess(),
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.cutTabData = res.data.data;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.cutTabOverlay = false;
        });

      this.inventoryTabOverlay = true;
      this.$axios
        .get(this.$services + "inventory/get_inventory", {
          params: {
            idCompany: userData.idCompany,
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.inventoryTabData = res.data.data;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.inventoryTabOverlay = false;
        });
    },
    getOrders() {
      const userData = JSON.parse(localStorage.getItem("userData"));

      this.historyOverlay = true;
      this.$axios
        .get(this.$services + "orders/get_provider_purchase_orders", {
          params: {
            idCompany: userData.idCompany,
            lastOrders: true,
          },
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.lastOrders = res.data.data;
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.historyOverlay = false;
        });
    },
    saveLogs() {
      const userData = JSON.parse(localStorage.getItem("userData"));

      // Si no hay logs, no se guardan
      if (!localStorage.getItem("items")) {
        return;
      }

      const logs = JSON.parse(localStorage.getItem("items"));
      this.$axios
        .post(this.$services + "general/save_storage", {
          data: logs,
          idCompany: userData.idCompany,
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            localStorage.removeItem("items");
            this.$swal({
              title: "Notificación",
              text: "Los logs se han guardado.",
              icon: "info",
              confirmButtonText: "Enterado",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else console.log("Error al guardar logs.");
        })
        .catch((error) => {
          this.$swal({
            title: "Notificación",
            text: "Error al guardar logs.",
            icon: "error",
            confirmButtonText: "Enterado",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {});
    },
    copyText() {
      // Traer las órdenes
      let orders = localStorage.getItem("unsavedOrders");

      navigator.clipboard.writeText(orders); // Copiar texto
    },
    notifyUser() {
      this.timerCount += 1;

      // Ya 5 alertas
      if (this.timerCount >= 5) {
        clearInterval(this.timer);
        this.timerCount = 0;
        this.getMetals(true);

        this.$swal({
          title: "Notificación",
          text: "La orden se ha cerrado automáticamente.",
          icon: "info",
          confirmButtonText: "Enterado",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        return;
      }

      this.$swal({
        title: "¡Cuidado!",
        text:
          "Tienes una orden pendiente por cerrar. En " +
          (5 - this.timerCount) +
          " " +
          (this.timerCount == 4 ? "minuto" : "minutos") +
          " se cerrará automáticamente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Voy a revisarlo",
        cancelButtonText: "Posponer 5 minutos",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.dismiss === "cancel") {
          // Posponer timer
          clearInterval(this.timer);
          this.timer = setInterval(this.notifyUser, 300000); // 5 minutos
          this.timerCount = 0;
          return;
        }
      });

      const audio = new Audio(notification);
      audio.play();

      clearInterval(this.timer);
      this.timer = setInterval(this.notifyUser, 60000); // Cada 1 minuto
    },
    sendSMS() {
      this.$axios
        .post(this.$services + "messages/send_message", {
          texts: ["¡Tienes una orden pendiente por cerrar!"],
          numbers: ["+52" + String(this.companyData.companyPhone)],
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            console.log("SMS Enviado.");
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {});
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);

      if (this.invoiceData.items.length <= 0) {
        clearInterval(this.timer);
        this.timerCount = 0;
      }
    },
    weightAdjustment(type, index, event) {
      switch (type) {
        case "jumbo":
          this.invoiceData.items[index].jumbo = event;
          if (event) {
            this.invoiceData.items[index].quantity -= 3;
            if (this.invoiceData.items[index].quantity < 0)
              // Sin negativos
              this.invoiceData.items[index].quantity = 0;
          } else this.invoiceData.items[index].quantity += 3;
          break;

        case "bucket":
          this.invoiceData.items[index].bucket = event;
          if (event) {
            this.invoiceData.items[index].quantity -= 1;
            if (this.invoiceData.items[index].quantity < 0)
              // Sin negativos
              this.invoiceData.items[index].quantity = 0;
          } else this.invoiceData.items[index].quantity += 1;
          break;
      }

      this.invoiceData.items[index].subTotal =
        this.invoiceData.items[index].quantity *
        this.invoiceData.items[index].price;
    },
    calculatedValues(type) {
      let value = 0;

      if (type === "total") {
        this.invoiceData.items.forEach((element) => {
          value += element.subTotal;
        });

        let tax = value * 0.16;

        value = parseFloat(value.toFixed(2));
        tax = parseFloat(tax.toFixed(2));

        this.invoiceData.subTotal = value;
        this.invoiceData.orderBalance = value;
        this.invoiceData.tax = tax;

        return this.formatNumber(Math.round(value));
      }
    },
    createOrderInPOS() {
      if (!this.scaleConnected) {
        this.alertSocketNotConnected();
        return;
      }

      // No hay items
      if (this.invoiceData.items.length == 0) {
        this.validatorText();
        return;
      }

      // Actualizar modal de corte e inventario
      this.inventoryTabOverlay = true;
      this.historyOverlay = true;

      // Asignar puntos
      if (this.invoiceData.idPublicUser) {
        this.invoiceData.points = this.invoiceData.subTotal * 0.1;
        this.invoiceData.publicUserName = this.publicUser.name;
        this.invoiceData.publicUserPoints = this.publicUser.points;
      }

      // Asignar lugar
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.invoiceData.companyName = userData.companyName;

      // Preparar invoiceData
      this.invoiceData.idQuote = this.scaleData.idQuote;

      // Alterado Bascula
      this.invoiceData.percentageValue = 100 - this.adjustment * 100;

      // New dates
      this.invoiceData.creationDate = this.$moment(new Date()).valueOf();
      this.invoiceData.estimatedDate = this.$moment(new Date()).valueOf();
      this.invoiceData.deliveryDate = this.$moment(new Date()).valueOf();
      this.invoiceData.creationDateString = new Date();
      this.invoiceData.estimatedDateString = this.$moment().format(
        "YYYY-MM-DD"
      );
      this.invoiceData.deliveryDateString = this.$moment().format("YYYY-MM-DD");

      // Cambiar valores del invoiceData (báscula => reales)
      this.invoiceData.items.forEach((item) => {
        item.quantity = item.quantity / this.adjustment;
        item.subTotal = item.quantity * item.price;
        item.quantity = this.roundNumber(item.quantity)
        item.subTotal = this.roundNumber(item.subTotal)
      });
      this.invoiceData.total = this.roundNumber(this.invoiceData.total / this.adjustment);
      this.invoiceData.subTotal = this.roundNumber(this.invoiceData.subTotal / this.adjustment);


      socket.emit("createOrderInPOS", this.invoiceData);

      // Reiniciar pantalla
      clearInterval(this.timer);
      this.timerCount = 0;
      this.invoiceData.note = null;
      this.invoiceData.idCompany = null;
      this.invoiceData.points = 0;
      this.invoiceData.idPublicUser = null;
      this.idPublicUser = null;
      this.publicUser = {};
      this.userFoundMsg = "";
      this.invoiceData.items = [];
      this.scaleData.idItem = null;
      this.scaleData.quantity = null;
      this.scaleData.price = null;
      this.total = 0;
    },
    createOrderInCloud(order, transferAuthorization) {

      transferAuthorization.createdAt = this.$moment(new Date(transferAuthorization.createdAt)).valueOf()
      transferAuthorization.expiresAt = this.$moment(new Date(transferAuthorization.expiresAt)).valueOf()

      // Preparar copia del invoiceData por si el servicio falla
      let copy = JSON.parse(JSON.stringify(order));

      const service =
        this.scaleData.idOperation == 1
          ? "orders/create_purchase_order"
          : "orders/create_sale_order";
      this.$axios
        .post(this.$services + service, {
          invoiceData: order,
          auth: transferAuthorization,
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.showToast("success", String("¡Ticket cerrado con éxito!"));
          }
        })
        .catch((error) => {
          // Guardar la orden como "pendiente" en caso de error (ej. no hay internet)
          let unsavedOrders = JSON.parse(localStorage.getItem("unsavedOrders"));

          if (!unsavedOrders) unsavedOrders = [];

          unsavedOrders.push(copy);
          localStorage.setItem("unsavedOrders", JSON.stringify(unsavedOrders));

          this.showToast("danger", String("Error al cerrar ticket"));
          this.$verifyToken(error.response.data.data);
        })
        .finally(() => {
          this.getOrders();
          this.getInventory();
          this.saveButtonStatus = false;
        });
    },
    savePendingOrders() {
      // Guardar logs
      this.saveLogs();

      // Sacar órdenes pendientes
      let unsavedOrders = JSON.parse(localStorage.getItem("unsavedOrders"));
      let offlineOrders = [];

      if (!unsavedOrders) return;

      // Guardar las órdenes
      unsavedOrders.forEach((order) => {
        this.$axios
          .post(this.$services + "orders/create_purchase_order", {
            invoiceData: order,
          })
          .then((res) => {
            this.$refreshToken(res.headers["x-access-token"]);
            if (!res.data.error) {
              this.showToast("success", String("¡Ticket cerrado con éxito!"));
            }
          })
          .catch((error) => {
            this.showToast("danger", String("Error al cerrar ticket"));

            // Órdenes de nuevo que no se subieron
            offlineOrders.push(order);

            this.$verifyToken(error.response.data.data);
          })
          .finally(() => {});
      });

      // Quitar las órdenes
      localStorage.removeItem("unsavedOrders");
      localStorage.setItem("unsavedOrders", JSON.stringify(offlineOrders));
    },
    restartRaspberry() {
      socket.emit("restartRaspberry", "test");
    },
    tare() {
      socket.emit("tare", "reset");
    },
    initialConfigScale(){
      socket.emit("initialConfigScale", "c");
      this.$bvModal.show('calibrateScale')
    },
    calibrateScale() {
      socket.emit("calibrateScale", this.newWeight);
      this.newWeight = null
    },
    addNumber(number) {
      this.newWeight += number;
    },
    deleteNumber() {
      this.newWeight = this.newWeight.slice(0, -1);
    },
    prepareTruck() {
      this.getInventory();
      this.$bvModal.show("truckModal");
    },
    markItem(item) {
      this.$set(item, "sent", !item.sent);
    },
    validateTruck() {
      let valid = false;
      this.inventory.forEach((item) => {
        if (item.sent) valid = true;
      });
      return valid;
    },
    registerOutputInventory() {
      const userData = JSON.parse(localStorage.getItem("userData"));

      let creationDate = Date.now(); // Fecha

      let outputItems = this.inventory.filter((item) => item.sent);

      outputItems.forEach((item) => {
        // Items
        this.truckDeparture.push({
          creationDate: creationDate,
          idItem: item.idItem,
          idCompany: userData.idCompany,
        });
      });

      this.$axios
        .post(this.$services + "orders/create_inventory_departure", {
          inventoryOutput: this.truckDeparture,
        })
        .then((res) => {
          this.$refreshToken(res.headers["x-access-token"]);
          if (!res.data.error) {
            this.$swal({
              title: "Notificación",
              text: "Salida del camión registrada.",
              icon: "info",
              confirmButtonText: "Enterado",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$verifyToken(error.response.data.data);
          this.showToast(
            "danger",
            String("Error al registrar salida, intente de nuevo mas tarde.")
          );
        })
        .finally(() => {
          this.truckDeparture = [];
        });
    },
    checkMissingParameters() {
      if (
        this.scaleData.idItem == null ||
        ((this.scaleData.quantity == null || this.scaleData.quantity == "") &&
          this.scaleData.units == null) ||
        this.scaleData.price == null ||
        this.scaleData.price == ""
      )
        return true;
      return false;
    },
    showModal(id) {
      this.$bvModal.show(id);
    },
    scrollItems() {
      let div = document.getElementById("items-div");
      div.scrollTop = 0;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notificación",
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    formatNumber(value) {
      return utils.numberWithCommas(value);
    },
    validatorText() {
      this.$swal({
        title: "¡Cuidado!",
        text: "Por favor, llena todos los campos necesarios para proceder.",
        icon: "error",
        confirmButtonText: "Voy a revisarlo",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    alertSocketNotConnected() {
      this.$swal({
        title: "¡Cuidado!",
        text: "Servidor local no iniciado, contacta al administrador",
        icon: "error",
        confirmButtonText: "Enterado",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    restrictNumber(event) {
      // Sólo números pueden ser ingresados
      let keyCode = event.keyCode ? event.keyCode : event.which;
      let stringValue = event.target.value.toString();
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || stringValue.indexOf(".") != -1)
      ) {
        // 46 is dot
        event.preventDefault();
        return;
      }
    },
    roundNumber(number) {
      return Math.round(number * 100) / 100;
    },
    printOrder(order){
        const userData = JSON.parse(localStorage.getItem("userData"));

        //  create object
        const orderToPrint = {
            companyName: userData.companyName,
            percentageValue: order.percentageValue,
            authId: order.authId,
            creationDate: order.creationDate,
            items: order.items.map(item => {
               return {
                   alias: item.itemName,
                   quantity: item.quantity,
                   price: item.itemPrice,
                   subTotal: item.itemSubtotal
               }
            })
        }
      socket.emit("printOrderTwice", orderToPrint);
    }
  },
};
</script>
